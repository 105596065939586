import {isDigit, isEnglishText, isSpace, isValidationItemLength} from "./generalValidtion";
import {
    MAX_LENGTH_PASSWORD,
    MIN_LENGTH_PASSWORD,
} from "../../../constants/auth/authConstants";

export default function isValidationPassword(password){
    return (
        isValidationItemLength(password, MIN_LENGTH_PASSWORD, MAX_LENGTH_PASSWORD) &&
        isEnglishText(password) &&
        isUpperCaseAndLowerCaseAndEndEnglish(password) &&
        isDigit(password) &&
        isSpace(password)
    )
}

export function isUpperCaseAndLowerCaseAndEndEnglish(password){
    return /[a-z]/.test(password) && /[A-Z]/.test(password)
}

export function isPasswordIdentity(password, confirmPassword){
    return password === confirmPassword && password && confirmPassword
}

export function getErrorPasswordData(form){
    return {
        errorLengthText: isValidationItemLength(
            form.userPassword,
            MIN_LENGTH_PASSWORD,
            MAX_LENGTH_PASSWORD
        ),
        errorUpperLowerCaseText: isUpperCaseAndLowerCaseAndEndEnglish(form.userPassword),
        errorDigitText: isDigit(form.userPassword),
        errorSpaceText: isSpace(form.userPassword),
        errorEnglish: isEnglishText(form.userPassword),
    }
}