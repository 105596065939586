import styles from "../../assets/Auth.module.css";
import Input from "./Input/Input";
import Button from "./Button/Button";
import isValidationPassword, {getErrorPasswordData, isPasswordIdentity}
    from "../../utils/auth/formValidation/passwordValidation";
import {getErrorMessage, isEmpty} from "../../utils/auth/formValidation/generalValidtion";
import {placeholderText} from "../../constants/auth/placeholderConstants";
import {
    errorPasswordIdentity,
    errorPasswordValidationText,
} from "../../constants/auth/errorValidationConstants";

import useForm from "../../hooks/auth/ManageAuthForm";
import useError from "../../hooks/auth/ErrorApiAuth";
import {getEmptyFieldError, renderErrorMessage} from "../../utils/auth/error";
import RouteEnum from "../../constants/Enums/EnumRoutes";
import {getPartsUrlElements} from "../../utils/general/general";
import {resetConfirmationPasswordUser} from "../../api/auth";
import {useNavigate} from "react-router-dom";
import {RESET_CONFIRMATION_PASSWORD_API_URL} from "../../constants/auth/authConstants";

export default function FormResetPassword(){
    const {formInfo, formInputStyle, isClickSubmitBtn, setIsClickSubmitBtn, setInfoSwitch, updateInputStyleApiError} = useForm()
    const {errorApiAuthList, setErrorApiAuthList, isEmptyField} = useError()
    const navigate = useNavigate()
    const [uid, token] = getPartsUrlElements()
    const BODY_DATA = {
        uid: uid,
        token: token,
        password: formInfo.userPassword,
        re_password: formInfo.userConfirmPassword
    }
    const resetConfirmationPasswordUrl = `${RESET_CONFIRMATION_PASSWORD_API_URL}${uid}/${token}/`
    function handleResetPasswordClick() {
        setIsClickSubmitBtn(true)
        if(
            isValidationPassword(formInfo.userPassword) &&
            isPasswordIdentity(formInfo.userPassword, formInfo.userConfirmPassword)
        ){
            resetConfirmationPasswordUser(resetConfirmationPasswordUrl, BODY_DATA)
                .then(() => {
                    navigate(RouteEnum.SIGN_IN)
                })
                .catch(error => {
                    const errorKey = Object.keys(error.response.data)[0]
                    setErrorApiAuthList(error.response.data[errorKey])
                    updateInputStyleApiError()
                })
        }
    }

    return(
        <div className={styles.main_content}>
            <Input
                placeholder={placeholderText.inputPasswordText}
                type={"password"}
                authInputStyle = {formInputStyle.userPasswordInputStyle}
                isClickSubmitBtn={isEmptyField(formInfo.userPassword, isClickSubmitBtn)}
                setInfoSwitch={setInfoSwitch}
            >
                {formInfo.userPassword &&
                    getErrorMessage(getErrorPasswordData(formInfo),
                        renderErrorMessage,
                        errorPasswordValidationText
                    )
                }
                {getEmptyFieldError(isEmptyField, isClickSubmitBtn, formInfo.userPassword,
                    formInputStyle.userPasswordInputStyle)}
            </Input>
            <Input
                placeholder={placeholderText.inputConfirmPasswordText}
                type={"password"}
                authInputStyle = {formInputStyle.userConfirmPasswordInputStyle}
                isClickSubmitBtn={isEmptyField(formInfo.userConfirmPassword, isClickSubmitBtn)}
                setInfoSwitch={setInfoSwitch}
            >
                {(getEmptyFieldError(isEmptyField, isClickSubmitBtn, formInfo.userConfirmPassword,
                    formInputStyle.userConfirmPasswordInputStyle)) ||
                    (isClickSubmitBtn && !isPasswordIdentity(formInfo.userPassword, formInfo.userConfirmPassword) &&
                        renderErrorMessage(errorPasswordIdentity)) ||
                    (!isEmpty(errorApiAuthList) && renderErrorMessage(errorApiAuthList[0]))
                }
            </Input>

            <div className={styles.container_button}>
                <Button handleClick={handleResetPasswordClick}>Reset</Button>
            </div>
        </div>
    )
}