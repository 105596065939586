import {MIN_LENGTH_USERNAME, MAX_LENGTH_USERNAME} from "../../../constants/auth/authConstants";
import {isDigit, isEnglishText, isSpace, isValidationItemLength} from "./generalValidtion";

export function isValidationUsername(username){
    return (
        isValidationItemLength(
            username,
            MIN_LENGTH_USERNAME,
            MAX_LENGTH_USERNAME
        ) &&
        isSpace(username) &&
        isEnglishText(username) &&
        isFirstCharacterNotDigit(username)
    )
}

export function isFirstCharacterNotDigit(username){
    let firstCharacterUsername = username[0]
    return !(isDigit(firstCharacterUsername))
}


export function getErrorUsernameData(form){
    return {
        errorLengthText: isValidationItemLength(form.userName, MIN_LENGTH_USERNAME, MAX_LENGTH_USERNAME),
        errorFirstCharacterDigitText: isFirstCharacterNotDigit(form.userName),
        errorSpaceText: isSpace(form.userName),
        errorEnglish: isEnglishText(form.userName),
    }
}