import styles from "../../assets/Auth.module.css";
import Input from "./Input/Input";
import Button from "./Button/Button";
import {getErrorMessage, isEmpty} from "../../utils/auth/formValidation/generalValidtion";
import isValidationEmail, {getErrorEmailData} from "../../utils/auth/formValidation/emailValidation";
import {placeholderText} from "../../constants/auth/placeholderConstants";
import {errorEmailValidationText,} from "../../constants/auth/errorValidationConstants";
import RouteEnum from "../../constants/Enums/EnumRoutes";
import {getEmptyFieldError, renderErrorMessage} from "../../utils/auth/error";
import useForm from "../../hooks/auth/ManageAuthForm";
import useError from "../../hooks/auth/ErrorApiAuth";
import {resetPasswordUser} from "../../api/auth";

export default function FormForgotPassword(){
    const {formInfo, formInputStyle, isClickSubmitBtn, setIsClickSubmitBtn, setInfoSwitch, updateInputStyleApiError} = useForm()
    const {errorApiAuthList, setErrorApiAuthList, isEmptyField} = useError()
    const CHECK_EMAIL_MESSAGE = "Check your email"
    const BODY_DATA = {
        email: formInfo.userEmail
    }

    function handleClick() {
        setIsClickSubmitBtn(true)
        if(isValidationEmail(formInfo.userEmail)){
            resetPasswordUser(BODY_DATA)
                .then(() => {
                    setErrorApiAuthList([])
                })
                .catch(error => {
                    const errorKey = Object.keys(error.response.data)[0]
                    setErrorApiAuthList(error.response.data[errorKey])
                    updateInputStyleApiError()
                })
        }
    }

    return(
        <div className={styles.main_content}>
            <Input
                placeholder={placeholderText.inputEmailText}
                type={"email"}
                authInputStyle = {formInputStyle.userEmailInputStyle}
                isClickSubmitBtn={isEmptyField(formInfo.userEmail, isClickSubmitBtn)}
                setInfoSwitch={setInfoSwitch}
            >
                {formInfo.userEmail &&
                    getErrorMessage(getErrorEmailData(formInfo),
                        renderErrorMessage,
                        errorEmailValidationText
                    )
                }
                {getEmptyFieldError(isEmptyField, isClickSubmitBtn, formInfo.userEmail,
                    formInputStyle.userEmailInputStyle)}
                {(isClickSubmitBtn && isEmpty(errorApiAuthList) && renderErrorMessage(CHECK_EMAIL_MESSAGE)) ||
                    (!isEmpty(errorApiAuthList) && renderErrorMessage(errorApiAuthList))
                }

            </Input>

            <div className={styles.container_button}>
                <Button handleClick={handleClick}>Confirm</Button>
            </div>

            <div className={styles.container_redirect}>
                <span>Go back to <a href={RouteEnum.SIGN_IN}>log in</a> page?</span>
            </div>
        </div>
    )
}