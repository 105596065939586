import styles from "../../assets/Auth.module.css";
import { useEffect, useState } from "react";
import {ACTIVATION_ACCOUNT_API_URL} from "../../constants/auth/authConstants";
import { getPartsUrlElements } from "../../utils/general/general";
import RouteEnum from "../../constants/Enums/EnumRoutes";
import {activateAccountUser} from "../../api/auth";
import useError from "../../hooks/auth/ErrorApiAuth";
import useForm from "../../hooks/auth/ManageAuthForm";

export default function ActivateUser() {
    const [activationStatus, setActivationStatus] = useState(null);
    const [errorActivation] = useState(null);
    const {updateInputStyleApiError} = useForm()
    const {setErrorApiAuthList} = useError()

    useEffect(() => {
        const [uid, token] = getPartsUrlElements();
        const activationUrl = `${ACTIVATION_ACCOUNT_API_URL}${uid}/${token}/`

        activateAccountUser(activationUrl)
            .then((response) => {
                setActivationStatus(response.data.message || "Account activated successfully!");
            })
            .catch(error => {
                const errorKey = Object.keys(error.response.data)[0]
                setErrorApiAuthList(error.response.data[errorKey])
                updateInputStyleApiError()
            })
    });

    return (
        <div className={styles.outer_container}>
            <div className={styles.message_box}>
                {activationStatus && (
                    <div className={styles.success_message}>
                        <div className={styles.success_title}>Registration Successful!</div>
                        <div className={styles.success_text}>
                            Your account has been created successfully. You can now log in to your account.
                        </div>
                        <div className={styles.login_button}>
                            <a href={RouteEnum.SIGN_IN}>Go to Login</a>
                        </div>
                    </div>
                )}
                {errorActivation && <div className={styles.error_message}>Error: {errorActivation}</div>}
            </div>
        </div>
    );
}
