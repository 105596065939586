import styles from "./CardPlayer.module.css"

export function CardPlayer(){
    return (
        <div className={styles.card}>

            <div className={styles.profile}>
                <div className={styles.avatar}></div>

                <div className={styles.player_rating_container}>
                    <span className={styles.rating}>300</span>
                </div>
            </div>

            <div className={styles.name_player_container}>
                player1
            </div>

            <div className={styles.change_rating}>
                +25
            </div>
        </div>
    )
}