import styles from "../../assets/Auth.module.css";
import {errorEmptyField} from "../../constants/auth/errorValidationConstants";
import {isEmpty} from "./formValidation/generalValidtion";

export const renderErrorMessage = (errorMessage) => {
    return (
        <div className={styles.container_error}>
            {errorMessage}
        </div>
    );
}

export function getEmptyFieldError(isEmptyField, isClickSubmitBtn, inputDataField, inputStyleError){
    return (
        (isEmptyField(inputDataField, isClickSubmitBtn) && isEmpty(Object.keys(inputStyleError))) ||
        (isEmpty(inputDataField) && !isEmpty(Object.keys(inputStyleError)))
    ) ? renderErrorMessage(errorEmptyField) : null
}