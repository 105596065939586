import styles from "../../assets/Auth.module.css";
import FormForgotPassword from "../../components/AuthComponents/FormForgotPassword";

export default function ForgotPassword(){

    return (
        <div className={styles.outer_container}>
            <div className={styles.main_container}>
                <div className={styles.header}>
                    Reset password
                </div>
                <FormForgotPassword/>
            </div>
        </div>
    )
}