import styles from "../../assets/game.module.css"

import {CardPlayer} from "./Result/CardPlayer";
import {Link} from "react-router-dom";


export function GameResult(){


    return (
        <div>
             <div className={styles.btn_logout_container}>
                <span className={styles.btn_text}>
                    <Link to="/me" style={{color: "black"}}>logout</Link>
                </span>
             </div>

            <div className={styles.result}>
                <CardPlayer></CardPlayer>
                <div className={styles.VS_div}>
                    VS
                </div>
                <CardPlayer></CardPlayer>
            </div>

            <div className={styles.btn_container}>
                <span className={styles.btn_text}>
                    <Link to="/me" style={{color: "black"}}>continue</Link>
                </span>
            </div>
        </div>
    )
}