import Enum from "./EnumTemplate";

export default class RouteEnum extends Enum {
    static SIGN_IN = '/signin'
    static SIGN_UP = '/'
    static FORGOT_PASSWORD = '/forgotpassword'
    static RESET_PASSWORD = '/reset/confirmation/:uid/:token'
    static ACTIVATE_USER = '/user/activate/:uid/:token'
    static MAIN = '/main'
    static PROFILE = '/profile'
}
