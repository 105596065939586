import styles from "./PlayerDashboard.module.css"
import React, {useState} from "react";
import photo from "../../../static/avatar.svg";

export default function PlayerDashboard(){
    const fileInputRef = React.useRef(null);
    const [imageSrc, setImageSrc] = useState(photo);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    }

    const handleFileChange = (event) => {
        const files = event.target.files

        if (files.length > 0) {
            const selectedFile = files[0]
            const reader = new FileReader()

            reader.onload = (e) => {
                const imageUrl = e.target.result
                setImageSrc(imageUrl);
            };

            reader.readAsDataURL(selectedFile)
        }
    };

    return (
        <div className={styles.player_dashboard_card}>
            <div className={styles.photo_management}>
                <div className={styles.profile_photo} onClick={handleButtonClick}>
                    <img className={styles.photo_img} src={imageSrc} alt="Profile" />
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
            </div>
            <div className={styles.profile_management}>
                <div className={styles.profile_field}>
                    Username
                </div>

                <div className={styles.profile_field}>
                    Rating: 254
                </div>
            </div>
            <div className={styles.game_management}>
                <div className={styles.game_management_btn}>
                    Random game
                </div>
                <div className={styles.game_management_btn}>
                    VS Computer
                </div>
            </div>
        </div>
    )
}