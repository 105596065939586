import axios from "axios";
import {
    RESET_PASSWORD_API_URL,
    SIGN_IN_API_URL,
    SIGN_UP_API_URL,
    LOGOUT_API_URL
} from "../constants/auth/authConstants";

export const signInUser = (data) => {
    return axios.post(SIGN_IN_API_URL, data)
}

export const signUpUser = (data) => {
    return axios.post(SIGN_UP_API_URL, data)
}

export const logoutUser = (data, config) => {
    return axios.post(LOGOUT_API_URL, data, config)
}

export const resetPasswordUser = (data) => {
    return axios.post(RESET_PASSWORD_API_URL, data)
}

export const resetConfirmationPasswordUser = (url, data) => {
    return axios.post(url, data)
}

export const activateAccountUser = (url) => {
    return axios.get(url)
}