import styles from "../../assets/Auth.module.css";
import FormResetPassword from "../../components/AuthComponents/FormResetPassword";

export default function ResetPassword(){
    return (
        <div className={styles.outer_container}>
            <div className={styles.main_container}>
                <div className={styles.header}>
                    Reset password
                </div>
                <FormResetPassword/>
            </div>
        </div>
    )
}