import styles from "../../assets/Auth.module.css";
import FormSignIn from "../../components/AuthComponents/FormSignIn";

export default function SignIn(){

    return (
        <div className={styles.outer_container}>
            <div className={styles.main_container}>
                <div className={styles.header}>
                    Sign In
                </div>

                <FormSignIn/>
            </div>
        </div>
    )
}