import styles from "./Statistics.module.css";
import React from "react";

export default function CommonLeaderStatistic(){
    return (
        <div className={styles.leaders_statistics}>
            <table className={styles.game_statistics_table}>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Points</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>friend1</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>friend1</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>friend1</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>friend1</td>
                        <td>10</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}