import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';

import './App.css';
import SignUp from "../../pages/auth/SignUp";
import SignIn from "../../pages/auth/SignIn";
import ForgotPassword from "../../pages/auth/ForgotPassword";
import ResetPassword from "../../pages/auth/ResetPassword";
import ActivateUser from "../../pages/auth/ActivateUser";
import {GameResult} from "../GameComponents/GameResult";
import Main from "../../pages/main/Main";
import Profile from "../../pages/main/Profile"
import RouteEnum from "../../constants/Enums/EnumRoutes"
function App() {
    return (
        <div className='App'>
        <Router>
            <Routes>
                <Route exact path={RouteEnum.SIGN_IN} element={<SignIn/>}/>
                <Route exact path={RouteEnum.SIGN_UP} element={<SignUp/>}/>
                <Route exact path={RouteEnum.FORGOT_PASSWORD} element={<ForgotPassword/>}/>
                <Route exact path={RouteEnum.RESET_PASSWORD} element={<ResetPassword/>}/>
                <Route exact path={RouteEnum.ACTIVATE_USER} element={<ActivateUser/>}/>
                <Route exact path={RouteEnum.SIGN_IN} element={<GameResult/>}/>
                <Route exact path={RouteEnum.MAIN} element={<Main/>}/>
                <Route exact path={RouteEnum.PROFILE} element={<Profile/>}/>
            </Routes>
        </Router>
        </div>
    )
}

export default App;
