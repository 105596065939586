import {isEnglishText, isSpace, isValidationItemLength} from "./generalValidtion";
import {
    MAX_DOMAIN_PART_EMAIL,
    MAX_LENGTH_EMAIL, MAX_LOCAL_PART_EMAIL, MIN_DOMAIN_PART_EMAIL,
    MIN_LENGTH_EMAIL, MIN_LOCAL_PART_EMAIL,
} from "../../../constants/auth/authConstants";

export default function isValidationEmail(email){
    const localPartEmail = email.split("@")[0]
    const domainPartEmail = email.split("@")[1] || ""
    return(
        isValidationItemLength(localPartEmail, MIN_LOCAL_PART_EMAIL, MAX_LOCAL_PART_EMAIL) &&
        isValidationItemLength(domainPartEmail, MIN_DOMAIN_PART_EMAIL, MAX_DOMAIN_PART_EMAIL) &&
        isValidationItemLength(email, MIN_LENGTH_EMAIL, MAX_LENGTH_EMAIL) &&
        isEnglishText(email) &&
        isValidLocalPartEmailText(localPartEmail) &&
        isValidDomainEmailText(domainPartEmail) &&
        isCorrectEndingDomainEmailText(domainPartEmail) &&
        isSpace(email)
    )
}

function isValidDomainEmailText(domainPartEmail){
    return /^[a-zA-Z0-9.@-]+$/.test(domainPartEmail)
}

function isCorrectEndingDomainEmailText(domainPartEmail){
    const lastIndex = domainPartEmail.lastIndexOf(".") + 1
    return lastIndex ? domainPartEmail.length - lastIndex >= 2 : false
}

function isValidLocalPartEmailText(localPartEmail){
    const symbols = [".", "_", "-"]
    return symbols.every(symbol => !localPartEmail.endsWith(symbol))
}

export function getErrorEmailData(form){
    const localPartEmail = form.userEmail.split("@")[0]
    const domainPartEmail = form.userEmail.split("@")[1] || ""

    return {
        errorLocalPartLengthText: isValidationItemLength(localPartEmail, MIN_LOCAL_PART_EMAIL, MAX_LOCAL_PART_EMAIL),
        errorDomainPartLengthText: isValidationItemLength(domainPartEmail, MIN_DOMAIN_PART_EMAIL, MAX_DOMAIN_PART_EMAIL),
        errorLengthText: isValidationItemLength(form.userEmail, MIN_LENGTH_EMAIL, MAX_LENGTH_EMAIL),
        errorSpaceText: isSpace(form.userEmail),
        errorEnglish: isEnglishText(form.userEmail),
        errorCorrectLocalPartEmailText: isValidLocalPartEmailText(localPartEmail),
        errorCorrectDomainPartEmailText: isValidDomainEmailText(domainPartEmail),
        errorCorrectEndingDomainPartEmailText: isCorrectEndingDomainEmailText(domainPartEmail)
    }
}