export default class Enum {
    constructor(...values) {
        for (const value of values) {
            this[value] = value;
        }
    }

    static getValues() {
        return Object.values(this).filter(v => typeof v === 'string');
    }
}