import styles from "./Input.module.css";
import { useState } from "react";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputAdornment} from '@mui/material';

export default function Input({ placeholder, type, authInputStyle, isClickSubmitBtn, setInfoSwitch, children }) {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const handleToggleVisibility = () => {
        setIsPasswordVisible((prev) => !prev);
    }
    const borderErrorInputStyle = { border: "2px solid red" }

    const inputType = isPasswordVisible ? "text" : type;
    const inputBoardStyle = isClickSubmitBtn ? borderErrorInputStyle : authInputStyle;

    function handleInput(e) {
        setInfoSwitch(placeholder, e.target.value);
    }
    function getPasswordVisibleEye(){
        return (
            <span className={styles.eye} onClick={handleToggleVisibility}>
                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                </InputAdornment>
            </span>
        )
    }

    return (
        <div className={styles.container_input}>
            <div className={styles.main_context} style={inputBoardStyle}>
                <input
                    className={styles.input}
                    placeholder={placeholder}
                    onChange={handleInput}
                    type={inputType}
                    autoComplete="current-password"
                    style={{
                        backgroundColor: "rgb(217, 217, 217, 0.6)",
                    }}
                />
                {type === "password" && getPasswordVisibleEye()}
            </div>
            {children}
        </div>
    )
}
