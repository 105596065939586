import styles from "./Statistics.module.css";
import React from "react";

export default function FriendLeaderStatistic(){
    return (
         <div className={styles.friends_statistics}>
              <table className={styles.game_statistics_table}>
                  <thead>
                      <tr>
                          <th>Friend username</th>
                          <th>Points</th>
                          <th>Play with friend</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td>friend1</td>
                          <td>10</td>
                          <td>play</td>
                      </tr>
                      <tr>
                          <td>friend1</td>
                          <td>10</td>
                          <td>play</td>
                      </tr>
                      <tr>
                          <td>friend1</td>
                          <td>10</td>
                          <td>play</td>
                      </tr>
                      <tr>
                          <td>friend1</td>
                          <td>10</td>
                          <td>play</td>
                      </tr>

                  </tbody>
              </table>
         </div>
    )
}