export function isValidationItemLength(item, minItemLength, maxItemLength){
    const itemLength = item.length
    return minItemLength <= itemLength && itemLength <= maxItemLength
}

export function isSpace(item){
    return !(/\s/.test(item))
}

export function isDigit(item){
    return /[0-9]/.test(item)
}

export function isEnglishText(item){
    return /^[a-zA-Z0-9_.@-]+$/.test(item)
}

export function isValidText(item){
    return /^[a-zA-Z0-9_.@-]+$/.test(item)
}

export function isEmpty(item){
    return item.length === 0
}

export function getErrorMessage(errorData, renderErrorMessage, errorItemValidationData){
    for (let item in errorData){
        if(!errorData[item]){
            return renderErrorMessage(errorItemValidationData[item])
        }
    }
}
