import styles from "./GameStatistic.module.css"
import React from "react";

export default function GameStatistic(){
    return (
        <div className={styles.game_statistic_card}>
            <table className={styles.game_statistics_table}>
                  <thead>
                      <tr>
                          <th>Opponent</th>
                          <th>Points</th>
                          <th>Date</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td>friend1</td>
                          <td>10</td>
                          <td>06.09.2024</td>
                      </tr>
                      <tr>
                          <td>friend1</td>
                          <td>10</td>
                          <td>06.09.2024</td>
                      </tr>
                      <tr>
                          <td>friend1</td>
                          <td>10</td>
                          <td>06.09.2024</td>
                      </tr>
                      <tr>
                          <td>friend1</td>
                          <td>10</td>
                          <td>06.09.2024</td>
                      </tr>
                      <tr>
                          <td>friend1</td>
                          <td>10</td>
                          <td>06.09.2024</td>
                      </tr>
                      <tr>
                          <td>friend1</td>
                          <td>10</td>
                          <td>06.09.2024</td>
                      </tr>
                      <tr>
                          <td>friend1</td>
                          <td>10</td>
                          <td>06.09.2024</td>
                      </tr>
                  </tbody>
            </table>
        </div>
    )
}