import LogoutPanel from "../../components/MainComponents/Logout/LogoutPanel";
import React from "react";
import styles from "../../assets/Profile.module.css"
import PersonalPlayerDashboard from "../../components/ProfileComponents/ProfileInformation/PersonalPlayerDashboard";
import GameStatistic from "../../components/ProfileComponents/GameStatistic/GameStatistic";
export default function Profile(){
    return (
        <div className={styles.profile_page}>
            <LogoutPanel></LogoutPanel>

            <div className={styles.player_overview}>
                <PersonalPlayerDashboard></PersonalPlayerDashboard>
                <GameStatistic></GameStatistic>
            </div>
        </div>
    )
}