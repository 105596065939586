import styles from "./PersonalPlayerDashboard.module.css"
import React from "react";
import photo from "../../../static/avatar.svg";
export default function PersonalPlayerDashboard(){

    return (
        <div className={styles.profile_card}>
            <div className={styles.profile_photo}>
                <img className={styles.photo_img} src={photo} alt="Profile" />
            </div>
            <div className={styles.profile_username}>
                Username
            </div>
            <div className={styles.profile_achievements}>
                <div className={styles.profile_rating}>
                    <span className={styles.profile_name_field}>
                        Rating:
                    </span>
                    <span className={styles.profile_rating_value}>
                        600
                    </span>
                </div>
                <div className={styles.profile_victories}>
                    <span className={styles.profile_name_field}>
                        Victories:
                    </span>
                    <span className={styles.profile_victories_value}>
                        50
                    </span>
                </div>
                <div className={styles.profile_games}>
                    <span className={styles.profile_name_field}>
                        Games:
                    </span>
                    <span className={styles.profile_games_value}>
                        650
                    </span>
                </div>
                <div className={styles.profile_friends}>
                    <span className={styles.profile_name_field}>
                        Friends:
                    </span>
                    <span className={styles.profile_friends_value}>
                        45
                    </span>
                </div>
            </div>

            <div className={styles.add_friend_btn}>
                Add friend
            </div>
        </div>
    )
}