import {useState} from "react";
import {isValidationUsername} from "../../utils/auth/formValidation/usernameValidation";
import isValidationEmail from "../../utils/auth/formValidation/emailValidation";
import isValidationPassword from "../../utils/auth/formValidation/passwordValidation";
import {placeholderText} from "../../constants/auth/placeholderConstants";

export default function useForm(){
    const [formInfo, setFormInfo] = useState(
        {
            userName: "",
            userEmail: "",
            userPassword: "",
            userConfirmPassword: "",
        }
    )
        const [formInputStyle, setFormInputStyle] = useState(
            {
                userNameInputStyle: {},
                userEmailInputStyle: {},
                userPasswordInputStyle: {},
                userConfirmPasswordInputStyle: {},
            }
        )
    const [isClickSubmitBtn, setIsClickSubmitBtn] = useState(false)
    const [inputErrorStyle, setInputErrorStyle] = useState({})
    const errorBorderStyle = {border: "2px solid red"}

    function setForm(newData){
        setFormInfo({
            ...formInfo,
            ...newData
        })
    }

    function updateInputStyleApiError(){
        const newFormInputStyle = {
            userNameInputStyle: errorBorderStyle,
            userEmailInputStyle: errorBorderStyle,
            userPasswordInputStyle: errorBorderStyle,
            userConfirmPasswordInputStyle: errorBorderStyle,
        };
        setFormInputStyle(newFormInputStyle)
    }
    function updateInputStyle(fieldName, validationFieldCheck, inputValue) {
        setFormInputStyle(prevStyles => ({
            ...prevStyles,
            [fieldName]: validationFieldCheck(inputValue) ? {} : errorBorderStyle
        }))
    }
    function setInfoSwitch(placeholder, inputValue){
        setInputErrorStyle(inputErrorStyle)
        switch (placeholder) {
            case placeholderText.inputNameText:
                setForm({userName: inputValue})
                updateInputStyle('userNameInputStyle', isValidationUsername, inputValue)
                break
            case placeholderText.inputEmailText:
                setForm({userEmail: inputValue})
                updateInputStyle('userEmailInputStyle', isValidationEmail, inputValue)
                break
            case placeholderText.inputPasswordText:
                setForm({userPassword: inputValue})
                updateInputStyle('userPasswordInputStyle', isValidationPassword, inputValue)
                break
            case placeholderText.inputConfirmPasswordText:
                setForm({userConfirmPassword: inputValue})
                break
            default:
                break;
        }
    }

    return {formInfo, formInputStyle, isClickSubmitBtn, setIsClickSubmitBtn, updateInputStyleApiError, setInfoSwitch}
}