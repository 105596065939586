import {useState} from "react";
import {isEmpty} from "../../utils/auth/formValidation/generalValidtion";

export default function useError(){
    const [errorApiAuthList, setErrorApiAuthList] = useState([])

    function isEmptyField(fieldText, isClickSubmitBtn){
        return isEmpty(fieldText) && isClickSubmitBtn
    }

    return {errorApiAuthList, setErrorApiAuthList, isEmptyField}
}
