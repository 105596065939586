import styles from "../../assets/Auth.module.css";
import FormSignUp from "../../components/AuthComponents/FormSignUp";

export default function SignUp(){

    return (
        <div className={styles.outer_container}>
            <div className={styles.main_container}>
                <div className={styles.header}>
                    Sign Up
                </div>

                <FormSignUp/>
            </div>
        </div>

    )
}